// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audio-player {
  position: fixed;
  bottom: 69px;
  left: 20%;
  display: flex;
  align-items: center;
  flex-direction: row;

}

.stop-audio-button {
  background-color: red;
}

.stop-audio-button:hover {
  background-color: #cf0000;
}

.audio-visualizer {
  border: 1px solid #000;
  background-color: #f3f3f3;
  border-radius: 5px;
  height: 40px;
  width: 60px;
}`, "",{"version":3,"sources":["webpack://./src/components/AudioPlayer.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,mBAAmB;;AAErB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".audio-player {\n  position: fixed;\n  bottom: 69px;\n  left: 20%;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n\n}\n\n.stop-audio-button {\n  background-color: red;\n}\n\n.stop-audio-button:hover {\n  background-color: #cf0000;\n}\n\n.audio-visualizer {\n  border: 1px solid #000;\n  background-color: #f3f3f3;\n  border-radius: 5px;\n  height: 40px;\n  width: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
