import React, { useEffect, useRef, useState } from 'react';
import './AudioPlayer.css';

const AudioPlayer = ({ socket, onAudioPlaybackStatus }) => {
  const audioContextRef = useRef(null);
  const audioQueueRef = useRef([]);
  const isPlayingRef = useRef(false);
  const analyserRef = useRef(null);
  const canvasRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    onAudioPlaybackStatus(isPlaying);
  }, [isPlaying, onAudioPlaybackStatus]);

  useEffect(() => {
    if (socket) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 2048;

      socket.on('audioChunk', ({ chunk }) => {
        const chunkCopy = chunk.slice(0); // Make a copy of the ArrayBuffer to avoid the "detached ArrayBuffer" issue

        if (audioContextRef.current) {
          audioContextRef.current.decodeAudioData(chunkCopy, (audioBuffer) => {
            audioQueueRef.current.push(audioBuffer);
            if (!isPlayingRef.current) {
              playFromQueue();
            }
          }, (error) => {
            console.error('Error decoding audio data', error);
          });
        }
      });

      return () => {
        if (audioContextRef.current) {
          audioContextRef.current.close();
        }
        socket.off('audioChunk');
      };
    }
  }, [socket]);

  const initializeAudioContext = () => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    analyserRef.current = audioContextRef.current.createAnalyser();
    analyserRef.current.fftSize = 2048;
  };

  const drawVisualizer = () => {
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext('2d');
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      requestAnimationFrame(draw);
      analyserRef.current.getByteTimeDomainData(dataArray);

      canvasCtx.fillStyle = 'rgb(200, 200, 200)';
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

      canvasCtx.beginPath();
      const sliceWidth = canvas.width * 1.0 / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = v * canvas.height / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
    };

    draw();
  };

  const playFromQueue = () => {
    if (audioQueueRef.current.length === 0 || isPlayingRef.current) {
      return;
    }

    isPlayingRef.current = true;
    setIsPlaying(true);
    setAnimationClass('animate');

    const playNextChunk = () => {
      if (audioQueueRef.current.length === 0) {
        isPlayingRef.current = false; // Stop playing if queue is empty
        setIsPlaying(false);
        // onAudioPlaybackStatus(false); // informs the audio player has stopped
        setAnimationClass('');
        return;
      }

      const audioBuffer = audioQueueRef.current.shift();
      if (audioBuffer) {
        const source = audioContextRef.current.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(analyserRef.current);
        analyserRef.current.connect(audioContextRef.current.destination);
        source.start();

        source.onended = () => {
          playNextChunk(); // Continue playing the next chunk if available
        };
      }
    };

    playNextChunk(); // Start playing the first chunk
    drawVisualizer(); // Start the visualizer
  };

  const stopAudio = () => {
    if (audioContextRef.current && isPlayingRef.current) {
      audioContextRef.current.close().then(() => {
        initializeAudioContext();
        audioQueueRef.current = [];
        isPlayingRef.current = false;
        setIsPlaying(false);
        setAnimationClass('');
        // onAudioPlaybackStatus(false); // informs the audio player has stopped
      });
    }
  };

  return (
    <div className="audio-player">
      {isPlaying && 
        <button className="stop-audio-button" onClick={stopAudio} disabled={!isPlaying}>Stop Audio</button>
      }
      <canvas ref={canvasRef} width="600" height="200" className="audio-visualizer" style={{display:`${ isPlaying ? "inline-block" : "none" }`}}></canvas>
    </div>
  );
};

export default AudioPlayer;
