import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './SharepointFileBrowser.css';
import { SharepointDataIngesterTest } from './SharepointDataIngester.test';
import logo from './images/AI3-192.png';
import { FaFolder, FaCheck, FaPlus, FaMinus, FaRegFileAlt } from 'react-icons/fa';
import {
    ChonkyActions,
    ChonkyFileActionData,
    ChonkyIconName,
    defineFileAction,
    FileAction,
    FileArray,
    FullFileBrowser,
    FileBrowser,
    FileContextMenu,
    FileList,
    FileNavbar,
    FileToolbar,
    setChonkyDefaults,
} from 'chonky';
import { SharepointDataIngester } from './SharepointDataIngester';
import { 
  Button, Container, Form, 
  Grid, Header, Input, TextArea, Icon,
  Select, Confirm, Checkbox,
} from 'semantic-ui-react';

const useFileActions = (selectedFiles, setSelectedFileDetails, removeSelectedFile) => {
  return useMemo(() => {
    const fileActions = []; // Initializing an empty array of file actions  
    fileActions.push(
      defineFileAction(
        {
          id: `add_to_selected_files`,
          button: {
            name: `Pick file/folder`, // Button name for choosing a file
            contextMenu: true,
            icon: ChonkyIconName.config, // Setting the icon of the button
          },
        } ,
        ({ state }) => {
          // this code is executed when the custom action is started
          if (state.contextMenuTriggerFile) {
            setSelectedFileDetails(state.contextMenuTriggerFile); // Updating selected files when the button is clicked
          }
        }
      )
    );
    // add a custom action to unpick a file
    fileActions.push(
      defineFileAction(
        {
          id: `unpick_file`,
          button: {
            name: `Unpick file/folder`, // Button name for choosing a file
            contextMenu: true,
            icon: ChonkyIconName.clearSelection, // Setting the icon of the button
          },
        } ,
        ({ state }) => {
          // this code is executed when the custom action is started
          if (state.contextMenuTriggerFile) {
            removeSelectedFile(state.contextMenuTriggerFile); // Updating selected files when the button is clicked
          }
        }
      )
    );

    fileActions.push(
      defineFileAction(
        {
          id: `pick_all_files`,
          button: {
            name: `Pick ALL files/folders`, // Button name for choosing a file
            toolbar: true,
            contextMenu: true,
            icon: ChonkyIconName.selectAllFiles, // Setting the icon of the button
          },
        } ,
        ({ state }) => {
          // do nothing here. We'll treat it elsewhere
          
        }
      )
    );

    fileActions.push(
      defineFileAction(
        {
          id: `unpick_all_files`,
          button: {
            name: `Unpick ALL files/folders`, // Button name for choosing a file
            toolbar: true,
            contextMenu: true,
            icon: ChonkyIconName.clearSelection, // Setting the icon of the button
          },
        } ,
        ({ state }) => {
          // do nothing here. We'll treat it elsewhere
          
        }
      )
    );

    fileActions.push(
      defineFileAction(
        {
          id: `open_file_url`,
          button: {
            name: `Open file URL`, // Button name for choosing a file
            contextMenu: true,
            icon: ChonkyIconName.file, // Setting the icon of the button
          },
        } ,
        ({ state }) => {
          // do nothing here. We'll treat it elsewhere
          
        }
      )
    );
    fileActions.push(
      defineFileAction(
        {
          id: `load-ingester-plan`,
          button: {
            name: `Load Ingester Plan`, // Button name for choosing a file
            contextMenu: false,
            icon: ChonkyIconName.file, // Setting the icon of the button
          },
        } ,
        ({ state }) => {
          // do nothing here. We'll treat it elsewhere
          
        }
      )
    );

    // fileActions.push(ChonkyActions.SortFilesByName);
    
    return fileActions; // Returning the array of file actions  
  }, [selectedFiles, setSelectedFileDetails, removeSelectedFile]);
};


/**
 * Custom hook for file picker logic. A hook is a function that contains logic and state variables that can be reused across multiple components.
 *
 * @param {FileArray} files - Array of files.
 * @returns {Object} - Object containing file picker logic functions and state variables.
 */
const useFilePickerLogic = (filesAndFolderChain, onFileAction) => {  
  const [selectedFiles, setSelectedFiles] = useState([]);
  // const namesRef = useRef(names);  
  // useEffect(() => {  
  //     namesRef.current = names;  
  //     console.log(names);
  // }, [names]);  

  const setSelectedFileDetails = useCallback((file) => {
    setSelectedFiles((oldSelectedFiles) => {
      // Check if the file is already selected
      const isFileSelected = oldSelectedFiles.some((selectedFile) => selectedFile.id === file.id);
      if (isFileSelected) {
        return oldSelectedFiles; // Return the old selected files if the file is already selected
      } else {
        // Add the file to the selected files
        return [
          ...oldSelectedFiles,
          {
            id: file.id,
            name: file.name,
            isDir: file.isDir,
            parentReference: file.parentReference,
            folderChain: filesAndFolderChain.folderChain.map((fc) => fc.name).join('/'),
          },
        ];
      }
    });
  }, [filesAndFolderChain.folderChain]);

  const removeSelectedFile = useCallback((file) => {
    setSelectedFiles((oldSelectedFiles) => {
      return oldSelectedFiles.filter((selectedFile) => selectedFile.id !== file.id);
    });
  }, [filesAndFolderChain.folderChain]);

  /**
   * Returns an array of files with highlighted properties.
   *
   * @returns {Array} The array of highlighted files.
   */
  const highlightedFiles = useMemo(() => {  
    const newFiles = [...filesAndFolderChain.files]; // Creating a copy of the files array
    const selectedFileIds = selectedFiles.map(file => file.id); // Extracting the IDs of selected files
    const colors = ['#00421c']; // Array of colors for highlighting files (extended for more colors)
    
    for (let i = 0; i < newFiles.length; ++i) {  
        const file = newFiles[i];  
        if (!file) continue;
        
        const fileIndex = selectedFileIds.indexOf(file.id); // Getting the index based on the file ID
        if (fileIndex !== -1) {
            newFiles[i] = {  
                ...file,  
                icon: file.isDir ? file.icon : ChonkyIconName.dndDragging, // Setting the icon of the file  
                color: colors[fileIndex % colors.length], // Setting the color of the file, using modulo to loop through colors
            };  
        }  
    }  
    return [...newFiles]; // Returning the array of highlighted files
}, [filesAndFolderChain.files, selectedFiles]); // Updated dependency array

 

  // /**
  //  * Sets the name at the specified index in the names array.
  //  * 
  //  * @param {number} index - The index of the name to be updated.
  //  * @param {string} name - The new name to be set.
  //  */
  // const setName = useCallback(  
  //     (index, name) =>  
  //         setNames((oldNames) => {  
  //             const newNames = [...oldNames]; // Creating a copy of the names array  
  //             newNames[index] = name; // Updating the name at the specified index  
  //             return newNames; // Returning the updated names array  
  //         }),  
  //     []  
  // );  

  /**
   * Handles the file action.
   * @param {Object} data - The data object containing the file action information.
   */
  const handleFileAction = useCallback(  
      (data) => {  
          console.log(data.id);
          if (data.id === 'add_to_selected_files') {  
            // no need to do anything here. This is a custom action that is handled in useFileActions
          } 
          if (data.id === 'load-ingester-plan') {
            console.log('Loading ingestion plan:', data.payload.files);

            
            setSelectedFiles([...data.payload.files]);
          }
          
          if (data.id === 'mouse_click_file' && data.payload?.file?.isDir) {
            onFileAction("navigate-to-folder", data.payload.file);
          } else if (data.id === 'mouse_click_file' && data.payload?.clickType === "double" && !data.payload?.file?.isDir) {
            // File has been double-clicked. Select the file as data source or remove it from the selected files, if it's already selected
            console.log('Double-clicked file:', data.payload.file);
            if (selectedFiles.some((file) => file.id === data.payload.file.id)) {
              console.log('Removing selected file:', data.payload.file);
              removeSelectedFile(data.payload.file); 
            } else {
              setSelectedFileDetails(data.payload.file);
            }
          } else if (data.id === 'open_file_url' && !data.payload?.file?.isDir) {
              // request the URL to the server and open it in a new tab
              // onFileAction("open-file-url", data.payload.file);
              console.log('Open file URL:', data);
              onFileAction("open-file-url", data.state.contextMenuTriggerFile);

          } else if (data.id == 'open_files') {        
              console.log('Open files:', data.payload.targetFile.id);
              // locate the file in the filesAndFolderChain using data.payload.targetFile.id
              console.log('FolderChain:', filesAndFolderChain.folderChain);

              const file = filesAndFolderChain.folderChain.find(f => f.id === data.payload.targetFile.id);
              // if found, navigate to the file
              if (file) {
                  console.log('opening file:', file);
                  onFileAction("navigate-to-folder", file);
              }
          } else if (data.id === ChonkyActions.SelectAllFiles.id || data.id === 'pick_all_files') {
            // Select all files in the current folder
            filesAndFolderChain.files.forEach((file) => {
              setSelectedFileDetails(file);
            });
          } else if (data.id === 'unpick_all_files') {
            // Clear the selection
            filesAndFolderChain.files.forEach((file) => {
              removeSelectedFile(file);
            });
          }
      },  
      [setSelectedFileDetails, removeSelectedFile, selectedFiles, filesAndFolderChain, onFileAction]  
  );  

  return {  
      handleFileAction,  
      selectedFiles,  
      setSelectedFileDetails,  
      highlightedFiles,
      removeSelectedFile
  };  
};  

function sortFilesByDirectory(filesAndFolderChains) {
  // A helper function to compare two folder chains
  const compareFolderChains = (a, b) => {
    const chainA = a.folderChain.split('/');
    const chainB = b.folderChain.split('/');

    for (let i = 0; i < Math.min(chainA.length, chainB.length); i++) {
      if (chainA[i] !== chainB[i]) {
        return chainA[i].localeCompare(chainB[i]);
      }
    }

    // If one folder chain is a subset of the other, the shorter one comes first
    return chainA.length - chainB.length;
  };

  // Sort the files using the helper function
  filesAndFolderChains.sort((fileA, fileB) => {
    // First, sort by folder chain
    const folderComparison = compareFolderChains(fileA, fileB);
    if (folderComparison !== 0) {
      return folderComparison;
    }

    // If folder chains are the same, then sort by name
    return fileA.name.localeCompare(fileB.name);
  });

  return filesAndFolderChains;
};


function FileTree({ selectedFiles }) {
  const [sortedFiles, setSortedFiles] = useState(sortFilesByDirectory(selectedFiles));
  const [expandedFolders, setExpandedFolders] = useState({}); // State to keep track of expanded/collapsed folders

  useEffect(() => {
    setSortedFiles(sortFilesByDirectory(selectedFiles));
  }, [selectedFiles]);

  function toggleFolder(folderPath) {
    setExpandedFolders(prevState => ({
      ...prevState,
      [folderPath]: !prevState[folderPath]
    }));
  }

  function renderTree() {
    const fileTree = {};
    sortedFiles.forEach(file => {
      const path = file.folderChain.split('/');
      let currentLevel = fileTree;

      path.forEach((folderName, index) => {
        if (!currentLevel[folderName]) {
          currentLevel[folderName] = { __files__: [] };
        }
        if (index === path.length - 1) { 
          currentLevel[folderName].__files__.push(file);
        } else {
          currentLevel = currentLevel[folderName];
        }
      });
    });
    function modifyFileTree(fileTree) {
      for (const key in fileTree) {
        if (fileTree[key].__files__?.length > 0) {
          for (let i = 0; i < fileTree[key].__files__.length; i++) {
            if (fileTree[key].__files__[i].isDir) {
              const folderName = `${fileTree[key].__files__[i].name}`;
              for (const sibling in fileTree[key]) {
                if (sibling === folderName) {
                  fileTree[key][sibling].__selected__ = true;
                  const newFiles = fileTree[key].__files__.filter(f => f.id !== fileTree[key].__files__[i].id);
                  fileTree[key].__files__ = newFiles;
                }
              }
            }
          }
        }
        if (key !== '__files__') {
          modifyFileTree(fileTree[key]);
        }
      }
    }

    modifyFileTree(fileTree);



    const createTreeJSX = (node, level = 0, path = '') => {
      return Object.keys(node).map(folderName => {
        const folderPath = path ? `${path}/${folderName}` : folderName;
        if (folderName === '__files__') {
          return node[folderName].length > 0 ? (
            node[folderName].map(file => (
              <li key={file.id} style={{ marginLeft: `${level * 1}px` }}>
                {file.isDir ? (
                  <div>
                    <strong><FaCheck color="green"/> <FaFolder color="orange"/> <span style={{fontSize: 'small'}}>{file.name}</span></strong> 🟢 <span style={{fontSize:"small"}}>all children included</span>
                  </div>
                ) : (
                  <span style={{fontSize:'small'}}><FaCheck color="green"/> <FaRegFileAlt />{file.name}</span>
                )}
              </li>
            ))
          ) : (
            // <li style={{ marginLeft: `${level * 1}px` }}>No files</li>
            <></>
          );
        } else {
          const isFolderExpanded = expandedFolders[folderPath];
          return (
            <React.Fragment key={folderName}>
              <div 
                style={{ marginLeft: `${level * 1}px`, fontWeight: 'bold', cursor: 'pointer', fontSize: 'small' }}
                onClick={() => toggleFolder(folderPath)}
              >
                {folderName !== '__selected__' && 
                  <div style={{position:'relative',left:'-1.2em'}}>
                    {!isFolderExpanded ? <FaMinus /> : <FaPlus />} {/* Toggle icon */}
                    {node[folderName].__selected__ && <FaCheck color="green"/> } {/* Checkmark icon */}
                    <FaFolder color={node[folderName].__selected__ ? "orange" : "gray"}/> <span style={{fontSize: 'small'}} color={node[folderName].__selected__ ? "orange" : "gray"}>{folderName}</span> <span style={{fontWeight: "normal", fontSize:"small"}}>{node[folderName].__selected__? "🟡 (all children included)" : ""}</span>
                  </div>
                }
              </div>
              {!isFolderExpanded && ! node[folderName].__selected__ && <ul>{createTreeJSX(node[folderName], level + 1, folderPath)}</ul>} {/* Conditional rendering based on expansion */}
            </React.Fragment>
          );
        }
      });
    };

    return (
      <div className="selection-tree">
        <ul>{createTreeJSX(fileTree)}</ul>
      </div>
    );
  }

  return (
    <div className="file-tree">

      {renderTree()}
    </div>
  );
};


export function SharePointFileBrowser({ 
  filesAndFolderChain, 
  sharepointTenantName, 
  sharepointSitePath, 
  onBrowseSharepoint, 
  onFileAction, 
  onSaveSpIngestionPlan, 
  onOpenSpIngestionPlan, 
  onGetSavedSpIngestionPlans, ingesterPlan, ingesterPlansList,
  onClose
}) {
    const [url, setUrl] = useState('');
    const [knowledgeCategory, setKnowledgeCategory] = useState('');
    const [isSaved, setIsSaved] = useState(false);
    const [showOpenPopup, setShowOpenPopup] = useState(false);
    const [savedIngestionPlans, setSavedIngestionPlans] = useState([]);
    const { handleFileAction, selectedFiles, setSelectedFileDetails, highlightedFiles, removeSelectedFile } = useFilePickerLogic(  
      filesAndFolderChain, onFileAction
    );  
    const myFileActions = useFileActions(selectedFiles, setSelectedFileDetails, removeSelectedFile);

    const [showConfirmation, setShowConfirmation] = useState(false); // New state for confirmation dialog



    const handleDeleteButtonClick = () => {
       setShowConfirmation(true);
     }
     const handleConfirmDelete = () => {
       // Call the knowledge base and its ingester plan deletion logic here
       onSaveSpIngestionPlan("delete", knowledgeCategory);
       // eliminate it from savedIngestedPlans
       const newSavedIngestionPlans = savedIngestionPlans.filter(plan => plan !== knowledgeCategory);
       setSavedIngestionPlans([...newSavedIngestionPlans]);
       // Reset selected files and other state variables
       resetSelectedFiles();
       // Close the confirmation dialog
       setShowConfirmation(false);
     }
 
     const handleCancelDelete = () => {
       // Close the confirmation dialog
       setShowConfirmation(false);
     }

    useEffect(() => {
      if (ingesterPlan) {
        const expectedSchema = {
          name: 'string',
          files: 'array',
          filesAndFolderChain: 'array',
          sharepointTenantName: 'string',
          sharepointSitePath: 'string'
        };
        if (ingesterPlan.name && ingesterPlan.files.length > 0) {
          resetSelectedFiles();
          setKnowledgeCategory(ingesterPlan.name);
          setIsSaved(true);
          // selectedFiles.push(...ingesterPlan.files);
          // for (const file of ingesterPlan.files) {
          //   setSelectedFileDetails(file);
          // }
          handleFileAction({id: 'load-ingester-plan', payload: {files: ingesterPlan.files}});
          
        }
      }
    }, [ingesterPlan], [useFilePickerLogic]);

    useEffect(() => {
      if (ingesterPlansList) {
        setSavedIngestionPlans(ingesterPlansList);
      } else {
        setSavedIngestionPlans([]);
      }
    }, [ingesterPlansList]);



    const handleBrowseSharepoint = () => {
        onBrowseSharepoint(url);
    }

    const resetSelectedFiles = () => {
        for (const file of selectedFiles) {
            removeSelectedFile(file);
        }
        setKnowledgeCategory('');
        setIsSaved(false);
    }

    const handleSaveIngestionPlan = () => {
      const newIngestionPlan = {
        name: knowledgeCategory,
        files: selectedFiles,
        filesAndFolderChain: filesAndFolderChain,
        sharepointTenantName: sharepointTenantName,
        sharepointSitePath: sharepointSitePath
      }
      onSaveSpIngestionPlan("save", newIngestionPlan);
      setIsSaved(true);
    }

    const handleOpenButtonClick = () => {
      setShowOpenPopup(true);
      setSavedIngestionPlans(onGetSavedSpIngestionPlans());
      // setSavedIngestionPlans(["Plan 1", "Plan 2", "Plan 3"]);
    }

    const handleSelectPlan = (plan) => {
      // user selected an existing plan
      console.log(plan);
      setKnowledgeCategory(plan);
      setShowOpenPopup(false);
      onOpenSpIngestionPlan(plan); // send request to server
    }

    return (
        <div className="side-by-side-sharepoint-files-panel">
          <div className = "sharepoint-files-panel-left-column">
            <div className="sharepoint-input-form">
                <input
                    className="sharepoint-url-input"
                    type="text"
                    placeholder="Paste a SharePoint file's URL here to browse the repository"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleBrowseSharepoint();
                        }
                    }}
                />
                <button className="sharepoint-button-container" onClick={handleBrowseSharepoint}>Browse</button>
            </div>
            <div className="how-to-use-message">
              Right click the files or folders to include them as data sources.
            </div>
            <FileBrowser className="file-browser"
              // files={filesAndFolderChain.files} 
              folderChain={filesAndFolderChain.folderChain} 
              fileActions={myFileActions} 
              // onFileAction={handleAction}
              files={highlightedFiles}
              // from code example:
              onFileAction={handleFileAction}  
              disableSelection={true}
              defaultSortActionId={ChonkyActions.SortFilesByName.id} 
              enableDefaultFileOptions={[
                ChonkyActions.SortFilesByName.id,
              ]}
              disableDefaultFileActions={[  
                  ChonkyActions.OpenSelection.id,  
                  ChonkyActions.SelectAllFiles.id,  
                  ChonkyActions.ClearSelection.id,  
              ]}  
            >
              <FileNavbar />
              <FileToolbar />
              <FileList />
              <FileContextMenu />
            </FileBrowser>
          </div>
          <div className="selected-files-container">
              {/* save button */}
              <h3>Data Ingestion Planner</h3>
              <div className="close-button-container">
                <Button type="button" onClick={onClose} >Close</Button>
              </div>
              { sharepointTenantName &&
              <div style={{textAlign:'left', paddingLeft: '10px'}}>
                <div style={{align:'left'}}>Tenant: <strong>{sharepointTenantName}</strong></div>
                <div>Site: <strong>{sharepointSitePath}</strong></div>
                {isSaved && <div>Knowledge Category: <strong>{knowledgeCategory}</strong><button className="save-button" onClick={handleSaveIngestionPlan}>Save</button></div>}
                {!isSaved && 
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Knowledge Category:</div>
                    <input
                          type="text"
                          value={knowledgeCategory}
                          onChange={(e) => setKnowledgeCategory(e.target.value)}
                        />
                    { knowledgeCategory != ''&& <button className="save-button" onClick={handleSaveIngestionPlan}>Save</button> }
                  </div>
                }
              </div>
                
              }
              {showOpenPopup && ingesterPlansList &&
                <div className="open-popup">
                  <div className="open-popup-content">
                    {/* knowledge cagegory dropdown, using onGetSavedIngestionPlans() to populate it, and onOpenSpIngestionPlan(knowledgeCategory) to open it */}
                    <div>
                      <label>Open Ingestion Plan:</label>
                      <select onChange={(e) => handleSelectPlan(e.target.value)}>
                        <option value="">Select an existing ingestion plan...</option>
                        {savedIngestionPlans && savedIngestionPlans.map((plan, index) => (
                          <option key={index} value={plan}>
                            {plan}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              }
              <div style={{display: 'flex', justifyContent: 'right'}}>
                <button className="reset-button" onClick={resetSelectedFiles} >Reset</button>
                <button className="open-button" onClick={handleOpenButtonClick}>Open</button>
                {/* <button className="ingest-files">Ingest</button> */}
                <button className="delete-button" onClick={handleDeleteButtonClick}>Delete</button>
              </div>
              {/* Confirmation dialog */}
              {showConfirmation && (
                <div className="confirmation-dialog">
                  <div className="confirmation-dialog-content">
                    <p>Are you sure you want to delete this data ingestion plan?</p>
                    <div className="confirmation-dialog-buttons">
                      <button className="confirm-button" onClick={handleConfirmDelete}>Confirm</button>
                      <button className="cancel-button" onClick={handleCancelDelete}>Cancel</button>
                    </div>
                  </div>
                </div>
              )}
              <FileTree selectedFiles={selectedFiles} style={{fontSize: 'smaller'}}/>

          </div>
          {/* <FileSidePanel isOpen={true} onClose={() => console.log("side panel closed")} fileUrl={sidePanelFileUrl} /> */}
        </div>
    )
    // return (
    //   <div>
    //     <SharepointDataIngesterTest />
    //   </div>
      
    // )
}

