import React, { useState,useEffect } from 'react';
import introJs from 'intro.js';
import './styles/styles.css'; // Import the main SCSS file 
import './Tour.css';
import { set } from 'react-hook-form';



function Tour({ fireConfetti, triggerTourReset, handleTourReset }) {
  const [intro, setIntro] = useState(null);

  useEffect(() => {
    // if triggerTourReset is true, start the tour, otherwise do nothing
    // the state of triggerTourReset is managed in the parent component (App.js)
    if (triggerTourReset) {
      intro.setDontShowAgain(false);
      intro.start();
      handleTourReset();
    }
  }, [triggerTourReset, handleTourReset]);


  useEffect(() => {
    const intro = introJs();
    
    intro.setOptions({
      tooltipClass: 'tour-tooltip',
      steps: [
        {
          // element: "#welcome-lara-logo",
          intro: "Welcome to LARA, your TD SYNNEX AI assistant for everyday tasks! This is a welcome tour of the interface. Click 'Next' to continue.",
          position: 'bottom'
        },
        {
          element: '#guided-tour-button',
          intro: "In the future, you can start this tour again by clicking the Tour button in the top right corner.",
          position: 'left'
        },
        {
          element: '#message-input-textarea',
          intro: "This textbox is where you interact with LARA. You will type your messages and optionally paste images here when using GPT-4o or Gemini 1.5 Pro models. Models are the AI engines that power LARA.",
          position: 'top'
        },
        {
          element: '#conversation-starters',
          intro: "Do you need inspiration? Just click one of the conversation starters to see the AI in action (but wait, not yet).",
          position: 'top'
        },
        {
          
          element: '#new-chat-button',
          intro: "Click here to start a new chat with LARA. This will also take you back to the home screen that you can see right now.",
          position: 'left'
        },
        {
          element: '#assistant-dropdown', 
          intro: "Try a few of the built-in AI assistants. Each one has a different purpose. For example, 'About LARA' can explain about use cases and the available features.",
          position: 'bottom'
        },
        {
          element: '#marketplace-button-1', 
          intro: "Next, click the Marketplace button to see more AI assistants, use PromptStudio to practice Prompt Engineering, and go pro by creating you own AI Assistants.",
          position: 'left'
        },
        {
          element: 'div.toggle-button-area',
          intro: "Toggle between different AI models to see how they perform. Click the button to switch between models. GPT-4o is the most powerful model available in LARA. Both GPT-4o and Gemini 1.5 Pro can handle text and images.",
          position: 'right'
        },
        {
          intro: `That's it! Enjoy using LARA. Check the box "Don't show this again" to skip this tour next time.`
        }
      ],
    });
    intro.onstart(() => {
      setTimeout(() => {
        fireConfetti();
      }, 500);
    });
    intro.oncomplete(() => {
      fireConfetti();
    });
    setIntro(intro);
    intro.setOption("dontShowAgain", true).start();

  }, []);

  return null;
};

export default Tour;
