import React, { useState, useEffect, useRef } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import { FaMicrophone, FaStop } from 'react-icons/fa';
import './RecordView.css';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const RecordView = ({ onSaveAudio, isAISpeaking }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [blobURL, setBlobURL] = useState('');
    const [isBlocked, setIsBlocked] = useState(false);
    const [mediaStream, setMediaStream] = useState(null);
    const canvasRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const animationIdRef = useRef(null);
    const silenceStartRef = useRef(null);
    const silenceDetectedRef = useRef(false);
    const firstSoundDetectedRef = useRef(false);
    const hasNonSilentAudioRef = useRef(false); // Track if any non-silent audio was detected

    useEffect(() => {
        console.log('isAISpeaking: ', isAISpeaking);
        if (!isAISpeaking) {
            console.log("Turning on the microphone...");
            start();
        }
    }, [isAISpeaking]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.metaKey && event.altKey) {
                console.log('Record event');
                if (isRecording) {
                    stop();
                } else {
                    start();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            // Stop recording and media stream when component unmounts
            if (isRecording) {
                stopRecording();
            }
        };
    }, [isRecording]);

    const start = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream) => {
                    setIsBlocked(false);
                    setMediaStream(stream);
                    Mp3Recorder.start()
                        .then(() => {
                            setIsRecording(true);
                            hasNonSilentAudioRef.current = false; // Reset non-silent audio detection
                            setupVisualizer(stream);
                        }).catch((e) => console.error(e));
                })
                .catch(() => {
                    console.log('Permission Denied');
                    setIsBlocked(true);
                });
        } else {
            console.log('getUserMedia is not supported');
            setIsBlocked(true);
        }
    };

    const stopRecording = () => {
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => track.stop());
            setMediaStream(null);
        }

        if (animationIdRef.current) {
            cancelAnimationFrame(animationIdRef.current);
        }

        if (audioContextRef.current.state !== 'closed') {
            audioContextRef.current.close();
        }

        if (analyserRef.current) {
            analyserRef.current.disconnect();
        }

        if (Mp3Recorder) {
            Mp3Recorder.stop();
        }

        setBlobURL(''); 

        // Reset all refs
        silenceStartRef.current = null;
        silenceDetectedRef.current = false;
        firstSoundDetectedRef.current = false;
        hasNonSilentAudioRef.current = false;


    };

    const stop = () => {
        console.log("Stopping recording...");
        Mp3Recorder.stop().getMp3()
            .then(async ([buffer, blob]) => {
                console.log("Recording stopped, processing MP3...");
                const blobURL = URL.createObjectURL(blob);
                const file = new File(buffer, 'audio.mp3', {
                    type: blob.type,
                    lastModified: Date.now()
                });

                if (!hasNonSilentAudioRef.current) {
                    console.log("Recording contains only silence, aborting submission.");
                    setIsRecording(false);
                    stopRecording();
                    return;
                }

                const formData = new FormData();
                const fileName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.mp3';
                formData.append('file', file, fileName);
                onSaveAudio(formData);
                setBlobURL(blobURL);
                setIsRecording(false);

                stopRecording();
            }).catch((e) => {
                console.log(e);
                setIsRecording(false); // Ensure state is updated even if there's an error
                stopRecording();
            });
    };

    const setupVisualizer = (stream) => {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 2048;
        const bufferLength = analyserRef.current.frequencyBinCount;
        dataArrayRef.current = new Uint8Array(bufferLength);
        source.connect(analyserRef.current);
        draw();
    };

    const draw = () => {
        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext('2d');
        const WIDTH = canvas.width;
        const HEIGHT = canvas.height;

        const drawVisualizer = () => {
            animationIdRef.current = requestAnimationFrame(drawVisualizer);

            analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

            const threshold = 128;
            const silenceDuration = 1000;
            const isSilent = dataArrayRef.current.every(value => Math.abs(value - threshold) < 5);

            if (isSilent) {
                if (firstSoundDetectedRef.current) {
                    if (!silenceStartRef.current) {
                        silenceStartRef.current = Date.now();
                    } else if (Date.now() - silenceStartRef.current > silenceDuration) {
                        silenceDetectedRef.current = true;
                        console.log("Silence detected, stopping recording...");
                        stop();
                    }
                }
            } else {
                silenceStartRef.current = null;
                silenceDetectedRef.current = false;
                firstSoundDetectedRef.current = true;
                hasNonSilentAudioRef.current = true; // Mark that non-silent audio was detected
            }

            canvasCtx.fillStyle = 'rgb(200, 200, 200)';
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

            if (silenceDetectedRef.current) {
                canvasCtx.fillStyle = 'rgb(255, 0, 0)';
                canvasCtx.font = '24px Arial';
                canvasCtx.fillText('Silence Detected', WIDTH / 2 - 70, HEIGHT / 2);
            } else {
                canvasCtx.lineWidth = 2;
                canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

                canvasCtx.beginPath();

                const sliceWidth = WIDTH * 1.0 / analyserRef.current.frequencyBinCount;
                let x = 0;

                for (let i = 0; i < analyserRef.current.frequencyBinCount; i++) {
                    const v = dataArrayRef.current[i] / 128.0;
                    const y = v * HEIGHT / 2;

                    if (i === 0) {
                        canvasCtx.moveTo(x, y);
                    } else {
                        canvasCtx.lineTo(x, y);
                    }

                    x += sliceWidth;
                }

                canvasCtx.lineTo(canvas.width, canvas.height / 2);
                canvasCtx.stroke();
            }
        };

        drawVisualizer();
    };

    const getPlatformTooltip = () => {
        const platform = navigator.platform.toLowerCase();
        if (platform.includes('win')) {
            return "Press Alt+Control to start/stop recording";
        } else if (platform.includes('mac')) {
            return "Press Option+Command to start/stop recording";
        } else {
            return "Click to start recording";
        }
    };

    const tooltipMessage = getPlatformTooltip();

    return (
        <div className='mic-button-container'>
            <div>
                {isRecording ? (
                    <button title={tooltipMessage} onClick={stop} disabled={!isRecording} style={{ backgroundColor: 'red' }} className="mic-button">
                        <FaStop size={15} />
                    </button>
                ) : (
                    <button title={tooltipMessage} onClick={start} disabled={isRecording} className="mic-button">
                        <FaMicrophone size={15} />
                    </button>
                )}
            </div>
            <canvas ref={canvasRef} width="300" height="100" className={`visualizer ${isRecording ? 'visible' : 'hidden'}`}></canvas>
        </div>
    );
};

export default RecordView;
