import React, { useState, useEffect, useRef } from 'react';
import './ThumbsButtons.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { PiThumbsUp, PiThumbsDown, PiThumbsUpFill, PiThumbsDownFill } from 'react-icons/pi';

function ThumbsButtons ({onThumbsFeedback, messageId, sentiment: initialSentiment, comment: initialComment, darkMode}) {
  const [showPopup, setShowPopup] = useState(false);
  const [comment, setComment] = useState('');
  const [sentiment, setSentiment] = useState(initialSentiment); // Add this line
  const commentsPopUpRef = useRef(null);

  useEffect(() => {
    setSentiment(initialSentiment);
    setComment(initialComment);

    const handleEscKeyPress = (event) => {
      if (event.keyCode === 27) { // 27 is the keycode for ESC key
        setShowPopup(false);
      }
    };
    const handleClickOutside = (event) => {
      if (commentsPopUpRef.current && (!commentsPopUpRef.current.contains(event.target))) {
          setShowPopup(false);
      }
  };
    window.addEventListener('keydown', handleEscKeyPress);
    document.addEventListener('click', handleClickOutside);

    return () => {  
      window.removeEventListener('keydown', handleEscKeyPress);  
      document.removeEventListener('click', handleClickOutside);  
    }; 
  }, []);
  
  // toast: see https://fkhadra.github.io/react-toastify/introduction
  const handleThumbsUp = () => {
    setTimeout(() => setShowPopup(true), 0);
    onThumbsFeedback({sentiment: 'positive', comment, messageId})
    setSentiment("positive")
  };

  const handleThumbsDown = () => {
    setTimeout(() => setShowPopup(true), 0);
    onThumbsFeedback({sentiment: 'negative', comment, messageId})
    setSentiment("negative")
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
    //onThumbsFeedback({sentiment, comment, messageId})
  };

  const handlePopupSubmit = () => {
    onThumbsFeedback({ sentiment, comment, messageId });
    setShowPopup(false);
    toast.success("Thank you for your feedback");
  };

  return (
    <div className="thumbs-container">
      <button className={`thumbs-button ${sentiment === "positive" ? 'green' : ''} ${darkMode ? 'dark-mode' : ''}` } onClick={handleThumbsUp}>
        { sentiment === "positive" && <PiThumbsUpFill size={20} /> }
        { sentiment !== "positive" && <PiThumbsUp size={20} /> }
      </button>
      <button className={`thumbs-button ${sentiment === "negative" ? 'red' : ''} ${darkMode ? 'dark-mode' : ''}`} onClick={handleThumbsDown}>
        { sentiment === "negative" && <PiThumbsDownFill size={20} /> }
        { sentiment !== "negative" && <PiThumbsDown size={20} /> }
      </button>
      {showPopup && (
        <div ref={commentsPopUpRef} className="comment-popup">
          { sentiment === "positive" && (<><PiThumbsUpFill size={20} style={{color: "green"}}/> Positive – </>)}
          { sentiment === "negative" && (<><PiThumbsDownFill size={20} style={{color: "red"}} /> Negative – </>)}
          Thank you for your feedback!
          <textarea className="comment-textarea" value={comment} onChange={handleCommentChange} placeholder="Add a comment (optional)..." />
          <button className="submit-button" onClick={handlePopupSubmit}>Send Comments</button>
        </div>
      )}
    </div>
  );
};

export default ThumbsButtons;
