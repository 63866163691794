// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tour-tooltip {
/* transparency 50% */
  background-color: rgba(0, 0, 0, 0.85);
  color: rgb(71, 211, 227);
  border-radius: 10px;

}

.tour-tooltip .introjs-dontShowAgain label {
  color: rgb(150, 150, 150);
  background-color: unset;
}

.tour-tooltip .introjs-button {
  background-color: rgb(84, 84, 84);
  text-shadow: 1px 1px 0 #616161;
  color: white;
  border: 0px;
}

.introjs-helperLayer {
  background-color: rgba(168, 168, 168, 0.2);
  color: white;
  border: 0px;
  box-shadow: rgba(103, 103, 103, 0.5) 0px 0px 1px 2px, rgba(38, 38, 40, 0.2) 0px 0px 0px 5000px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Tour.css"],"names":[],"mappings":"AAAA;AACA,qBAAqB;EACnB,qCAAqC;EACrC,wBAAwB;EACxB,mBAAmB;;AAErB;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,iCAAiC;EACjC,8BAA8B;EAC9B,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,0CAA0C;EAC1C,YAAY;EACZ,WAAW;EACX,yGAAyG;AAC3G","sourcesContent":[".tour-tooltip {\n/* transparency 50% */\n  background-color: rgba(0, 0, 0, 0.85);\n  color: rgb(71, 211, 227);\n  border-radius: 10px;\n\n}\n\n.tour-tooltip .introjs-dontShowAgain label {\n  color: rgb(150, 150, 150);\n  background-color: unset;\n}\n\n.tour-tooltip .introjs-button {\n  background-color: rgb(84, 84, 84);\n  text-shadow: 1px 1px 0 #616161;\n  color: white;\n  border: 0px;\n}\n\n.introjs-helperLayer {\n  background-color: rgba(168, 168, 168, 0.2);\n  color: white;\n  border: 0px;\n  box-shadow: rgba(103, 103, 103, 0.5) 0px 0px 1px 2px, rgba(38, 38, 40, 0.2) 0px 0px 0px 5000px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
