// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mic-button-container {
    position: absolute;
    bottom: -2px;
    left: -2px;
    z-index: 3;
    display: flex;
    justify-content: center;
    padding: 0px;
}
.mic-button {
    height: 1.8em;
    width: 1.8em;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing:border-box;
}

.visualizer {
    border: 1px solid black;
    margin-top: 10px;
    border-radius: 5px;
    width: 60px;
    height: 30px;
    display: block;
    z-index: 3;
}

.visualizer {
    border: 1px solid black;
    margin-top: 10px;
    display: none; /* Default to hidden */
    z-index: 3;
}

.visualizer.visible {
    display: block; /* Show when recording */

}

.visualizer.hidden {
    display: none; /* Hide when not recording */
}
`, "",{"version":3,"sources":["webpack://./src/components/RecordView.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,YAAY;AAChB;AACA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,cAAc;IACd,UAAU;AACd;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,aAAa,EAAE,sBAAsB;IACrC,UAAU;AACd;;AAEA;IACI,cAAc,EAAE,wBAAwB;;AAE5C;;AAEA;IACI,aAAa,EAAE,4BAA4B;AAC/C","sourcesContent":[".mic-button-container {\n    position: absolute;\n    bottom: -2px;\n    left: -2px;\n    z-index: 3;\n    display: flex;\n    justify-content: center;\n    padding: 0px;\n}\n.mic-button {\n    height: 1.8em;\n    width: 1.8em;\n    padding: 5px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    box-sizing:border-box;\n}\n\n.visualizer {\n    border: 1px solid black;\n    margin-top: 10px;\n    border-radius: 5px;\n    width: 60px;\n    height: 30px;\n    display: block;\n    z-index: 3;\n}\n\n.visualizer {\n    border: 1px solid black;\n    margin-top: 10px;\n    display: none; /* Default to hidden */\n    z-index: 3;\n}\n\n.visualizer.visible {\n    display: block; /* Show when recording */\n\n}\n\n.visualizer.hidden {\n    display: none; /* Hide when not recording */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
