import './MemoryAndMore.css';
import { FaExpandArrowsAlt } from "react-icons/fa";


// Note: now that there is a KnowledgeManager component, this component is only used as a launch point for the KnowledgeManager
export function MemoryAndMore({ AIModel, tokenCount, prompt, userFirstName, onClickKnowledgeManager, stats }) {

  return (
    <div className="memory-and-more">
      <div className="tokens">Online LARA users: {stats.usersCount}</div>
      <div className="tokens">Active LARA sessions: {stats.sessionsCount}</div>
      <div className="AIModelLabel">Current Model: {AIModel}</div>
      <div className="tokens">Tokens: {tokenCount}</div>
      {/* <div className="tokens">System prompt: {prompt}</div> */}
      <div>&nbsp;</div>

        
      {/* <span className="subtitle">Items listed as "Category": file name or URL</span>
      {Array.isArray(sortedMemoryFiles) && sortedMemoryFiles.map((file, index) => {
        return (
          <div key={index} className="memory-file">
            <span className="memory-file-name"><strong>{file.Category}</strong>: {file.originalFilename}</span>
          </div>
        );
      })} */}
    </div>
  );
}
