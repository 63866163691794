// create a component called TermsOfUse
import React from 'react'
import './TermsOfUse.css'
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import tdsLogo from './images/TD SYNNEX logo color.png';
import laraLogo from './images/AI3-192.png';
import {AiFillCheckCircle, AiFillCloseCircle} from 'react-icons/ai';

export function TermsOfUse({ onTermsOfUseClick, userFullName, acceptedTerms, darkMode }) {
    const [isChecked, setIsChecked] = useState(false);
    //const [accepted, setAccepted] = useState(false);
    const [termsOfUse, setTermsOfUse] = useState(
        [
        {   title:"Terms of Use\n\n", 
text: `Welcome to LARA (Language-Aware Responsive AI), an AI-powered chat application, currently presented as a Proof-of-Concept. Your use of this tool is subject to TD SYNNEX’s Policies, including, but not limited to the **Cybersecurity and Acceptable Use policy** and **Data Classification & Handling policy** which can be accessed at Policy Central ([click to open](https://tdworldwide.sharepoint.com/:u:/r/sites/Legal-enc/SitePages/Policy-Central.aspx?csf=1&web=1&e=uZjfTM)). Please consider the following guidelines when using Generative AI:`,
do: `- Do comply with all laws, regulations and company policies.\n
- Do thoroughly check AI output for accuracy, relevancy and completeness.\n
- Do check AI output to ensure it is free from bias or discrimination.\n
- Do review the AI generated content to ensure the intended use is not restricted by any intellectual property rights (e.g. copyright) belonging to another company or individual.\n
- Do review the terms of use to understand whether the data you upload will be included in responses to other user's requests.`,
            doNot: `- Don't use personal data or business confidential information in public Al tools.\n
- Don't use code generated by AI without review.\n
- Don't use any AI generated content for any business purpose without fact checking.\n
- Don't publish AI generated communications to co-workers or business partners without review and approval from the corporate communications team.\n
- Don't use AI as a substitute for human creativity and decision-making.\n
- Don't expect AI to be perfect.`,
            }
        ]
    );
    function LinkRenderer(props: any) {
        return (
          <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
          </a>
        );
    }

    const GreenCheckRenderer = ({ index, children }) => {
        const Icon = AiFillCheckCircle;
        const color = 'green';
      
        return (
            <li style={{ listStyleType: 'none' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon style={{ color, minWidth: '24px', minHeight: '24px', marginRight: '1rem' }} />
                <div>{children}</div>
              </div>
            </li>
          );
      };

      const RedXRenderer = ({ index, children }) => {
        const Icon = AiFillCloseCircle;
        const color = 'red';
      
        return (
            <li style={{ listStyleType: 'none' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon style={{ color, minWidth: '24px', minHeight: '24px', marginRight: '1rem' }} />
                <div>{children}</div>
              </div>
            </li>
          );
      };

      function handleAccept() {
        //setAccepted(true);
        onTermsOfUseClick();
      }

    return (
        <>
            {!acceptedTerms &&    
                <div className="overlay">
                    <div className="terms-of-use-container">
                        <img src={laraLogo} alt="LARA logo" className="lara-logo" />
                        <img src={tdsLogo} alt="TD SYNNEX logo"  className="tds-logo" />
                        <div className="title" ><ReactMarkdown>{termsOfUse[0].title}</ReactMarkdown></div>
                        <div className="text" ><ReactMarkdown components={{ a: LinkRenderer}}>{termsOfUse[0].text}</ReactMarkdown></div>
                        {/* add do and doNot in two columns */}
                        <div className="doAndDoNot">

                                <div className="do">
                                    <ReactMarkdown components={{ li: GreenCheckRenderer}}>{termsOfUse[0].do}</ReactMarkdown>
                                </div>

                                <div>
                                    <ReactMarkdown components={{ li: RedXRenderer}} children={termsOfUse[0].doNot} />
                                </div>

                        </div>
                            

                        {/* add a check box to accept the terms */}
                        <div className="checkbox">
                            {/* initialize the value of the checkbox to that of variable checkMark */}
                            <label className="checkBoxLabel" htmlFor="accept">I, {userFullName}, have read and accept the terms of use (check the box to confirm):</label>
                            <input type="checkbox" checked={isChecked} id="accept" name="accept" value="accept" onChange={() => setIsChecked(!isChecked)} /> 

                        </div>
                        {/* add the Accept and Reject buttons */}
                        <div className="buttons">
                            <button disabled={!isChecked} className={`accept ${isChecked ? '' : 'disabled'}`} onClick={handleAccept}>Start using LARA</button>
                        </div>

                    </div>  
                </div>
            }
        </>
    );
    }