// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sharepoint-data-ingester {
  padding: 30px;
  box-sizing: border-box;
  /* scroll vertically */
  overflow-y: scroll;
  
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000;
  width: 100%;
  background-color: white;
  text-align: left;
}

.sharepoint-data-ingester th,td {
  font-size: small;
  margin-top: 0px;

}

.ingester-plans-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30em;
  align-content: center;
  /* vertical alignment: center */
  align-items: center;
}

.ingester-plans-container select{

  width: 20em;
  align-items: center;
  margin-top: 10px;
}

.start-ingestion-buttons {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 10px;
}

.data-ingester-row {
  padding:0;
  margin:0;
}

.subject-input {
  width: 30em;
}

.magic-subjects {
  position: relative;
 
  right: 0;

}`, "",{"version":3,"sources":["webpack://./src/components/SharepointDataIngester.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;;EAElB,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,cAAc;EACd,WAAW;EACX,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;;AAEjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,qBAAqB;EACrB,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;;EAEE,WAAW;EACX,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,QAAQ;AACV;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;;EAElB,QAAQ;;AAEV","sourcesContent":[".sharepoint-data-ingester {\n  padding: 30px;\n  box-sizing: border-box;\n  /* scroll vertically */\n  overflow-y: scroll;\n  \n  position: absolute;\n  left: 0;\n  top: 0;\n  z-index: 10000;\n  width: 100%;\n  background-color: white;\n  text-align: left;\n}\n\n.sharepoint-data-ingester th,td {\n  font-size: small;\n  margin-top: 0px;\n\n}\n\n.ingester-plans-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 30em;\n  align-content: center;\n  /* vertical alignment: center */\n  align-items: center;\n}\n\n.ingester-plans-container select{\n\n  width: 20em;\n  align-items: center;\n  margin-top: 10px;\n}\n\n.start-ingestion-buttons {\n  position: absolute;\n  top: 0;\n  right: 0;\n  padding-right: 10px;\n}\n\n.data-ingester-row {\n  padding:0;\n  margin:0;\n}\n\n.subject-input {\n  width: 30em;\n}\n\n.magic-subjects {\n  position: relative;\n \n  right: 0;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
