// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.username-container {
    position: relative;
    display: flex;
    /* arrange items horizontally */
    flex-direction: row;
    /* align items in the center */
    align-items: center;
    /* add space between items */
    }
    .user-photo {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .user-photo img {

        height: 100%;
        border-radius: 50%;
    }
    
    .username-button {
        text-transform: none !important;
        color: unset !important;
    }`, "",{"version":3,"sources":["webpack://./src/components/Username.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,+BAA+B;IAC/B,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,4BAA4B;IAC5B;IACA;QACI,WAAW;QACX,YAAY;QACZ,kBAAkB;QAClB,iBAAiB;QACjB,kBAAkB;IACtB;;IAEA;;QAEI,YAAY;QACZ,kBAAkB;IACtB;;IAEA;QACI,+BAA+B;QAC/B,uBAAuB;IAC3B","sourcesContent":[".username-container {\n    position: relative;\n    display: flex;\n    /* arrange items horizontally */\n    flex-direction: row;\n    /* align items in the center */\n    align-items: center;\n    /* add space between items */\n    }\n    .user-photo {\n        width: 24px;\n        height: 24px;\n        border-radius: 50%;\n        margin-left: 10px;\n        margin-right: 10px;\n    }\n\n    .user-photo img {\n\n        height: 100%;\n        border-radius: 50%;\n    }\n    \n    .username-button {\n        text-transform: none !important;\n        color: unset !important;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
