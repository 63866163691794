import React from 'react';
import './LoadingSpinner.css'; // CSS file for styling

const LoadingSpinner = () => {
  return (
    <>
        Loading... <div className="loading-spinner"></div>
    </>
  );
}

export default LoadingSpinner;
