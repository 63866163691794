import { set } from 'react-hook-form';
import './ConversationStarters.css';
import { useState, useEffect } from 'react';

export function ConversationStarters({ onConversationStarterClick, darkMode, activeProducts, assistantConversationStarters }) {
  // title is optional: if used, it will show a shorter text
  // text is mandatory: this is the message that will be sento to the AI on the user's behalf
  // model is optional (must be lowercase and must match the model names used in ToggleButton.js)
  const [conversationStarters, setConversationStarters] = useState([]);
  useEffect(() => {
    setConversationStarters(assistantConversationStarters);
  }, [assistantConversationStarters]);
  



  // useEffect(() => {
  //   const starters = [];
  //   if (activeProducts.has('streamone-ion-kb-internal')) {
  //     // append another array to starters
  //     starters.push(
  //       [
  //         {
  //           title: "What is StreamOne Ion?",
  //           text: `Explain what StreamOne Ion is in a concise way. Do not show links to documentation, at first. 
  //           Encourage me to continue the conversation by suggesting a next question based on the knowledge provided. 
  //           For example (think of your own example): 'I can describe how a reseller is onboarded in StreamOne Ion. 
  //           Would you like more information?'. `, promptName: "streamoneion"
  //         },
  //         { title: "Pain points StreamOne resolves", text: "What are typical customer pain points that StreamOne can resolve?", promptName: "streamoneion" },
  //         { title: "StreamOne Ion benefits", text: "What are the benefits of StreamOne Ion?", promptName: "streamoneion" },
  //         { title: "Choosing an orchestration platform", text: "How should I choose an orchestration platform?", promptName: "streamoneion" },
  //         { title: "StreamOne security", text: "How are cloud orchestration platforms handling security?", promptName: "streamoneion" },
  //         { title: "StreamOne Ion getting started", text: "How can I get started with StreamOne Ion?", promptName: "streamoneion" },
  //         { title: "StreamOne Ion videos", text: "List some StreamOne Ion videos", promptName: "streamoneion" },
  //         { title: "Managing credit", text: "How can I manage credit?", promptName: "streamoneion" },
  //         { title: "StreamOne Ion training", text: "List StreamOne Ion training resources", promptName: "streamoneion" },
  //         { title: "Value proposition", text: "What is the StreamOne Ion value proposition?", promptName: "streamoneion" },
  //         { title: "Promote StreamOne Ion API", text: "You are a promoter of TD SYNNEX's StreamOne Ion (R) API. Take the content from https://www.tdsynnex.com/ion/api/ and start chatting to me (the customer)", promptName: "assistant" }
  //       ]
  //     );
  //   }
  //   if (activeProducts.has('genai-reasearch-package')) {
  //     starters.push(
  //       [
  //         {
  //           title: "Surprise me with a coding idea", model: "gpt-4", promptName: "concise",
  //           text: "Surprise me with a coding idea in React to make a chat application more interesting. Think of an AI chat application and how it can be monetized by TD SYNNEX, leveraging its partner ecosystem, solutions databases and more."
  //         },
  //         { title: "Draw a GenAI security poster", model: "gpt-4", text: "You are an artist. First, think of an idea for a GenAI Security campaign poster. Then, tell the user your idea and offer a couple of choices. Then, suggest a few color palettes and wait for the user to answer. Finally, draw the painting.", promptName: "assistant" },
  //         { title: "Step-by-step image generation", text: "Act as an artist. Use a professional tone. You will guide the user through the steps to create great images. Think step-by-step: Follow each of these steps, waiting for the user to answer before proceeding. First, identify the Core Concept: Start by identifying the main subject or theme of your image. It could be anything from a 'serene beach' to a 'haunted house'. For instance, let's say we choose 'a futuristic cityscape'. Wait for the user to answer. Then, add Specific Details: After you've identified the core concept, add specific details that you want the AI to incorporate. These could include colors, time of day, weather conditions, and other elements that make up the scene. For example, 'a futuristic cityscape at sunset with towering skyscrapers and neon lights'. Wait for the user to answer. Then, incorporate Style Keywords: Next, add style keywords to guide the aesthetics of the generated image. These can include artistic styles like 'surreal', 'impressionist', or 'minimalistic', or they can reflect the mood you want to convey, such as 'dystopian', 'utopian', or 'dream-like'. For instance, 'a dream-like, futuristic cityscape at sunset with towering skyscrapers and neon lights'. Wait for the user to answer. Use Comparative Language: Use comparative language to give the AI a better understanding of what you're envisioning. For example, 'a dream-like, futuristic cityscape at sunset with towering skyscrapers higher than today's tallest buildings and neon lights brighter than Times Square'. Wait for the user to answer. Experiment and Iterate: Finally, don't be afraid to experiment and iterate on your prompts. If the initial images aren't quite what you envisioned, try rephrasing your prompt or adding/removing details until you get the results you want.", promptName: "assistant"},
  //         {
  //           title: "Fetch GenAI news", text: "Fetch the latest news about GenAI",
  //           promptName: "researcher"
  //         },
  //         { title: "Write a price predictor in Python", text: "Write Python code that can predict market prices for a new product based on similar product sales. Generate some example data. Use machine learning techniques.", promptName: "researcher" },
  //         {
  //           title: "Design an AI-powered virtual assistant",
  //           text: "Design an AI-powered virtual assistant that can assist users with their daily tasks, provide personalized recommendations, and answer questions. Consider how this virtual assistant can integrate with TD SYNNEX's partner ecosystem and solutions databases to enhance its functionality and value."
  //         },
  //         {
  //           title: "Create a GenAI marketing campaign",
  //           text: "Create a GenAI marketing campaign to promote the benefits of AI technology in various industries. Think about different marketing strategies, target audiences, and key messages that can highlight the value of GenAI solutions offered by TD SYNNEX."
  //         },
  //         {
  //           title: "Develop an AI-driven recommendation system",
  //           text: "Develop an AI-driven recommendation system that can analyze user preferences, browsing history, and purchase behavior to provide personalized product recommendations. Explore how this recommendation system can leverage GenAI capabilities to improve customer satisfaction and drive sales."
  //         },
  //         {
  //           title: "Explore the ethical implications of GenAI",
  //           text: "Discuss the ethical implications of using GenAI in various domains, such as healthcare, finance, and privacy. Consider the potential risks and benefits associated with GenAI technologies and propose guidelines or frameworks that TD SYNNEX can adopt to ensure responsible and ethical use of GenAI."
  //         },
  //         {
  //           title: "French coach",
  //           text: "You are a French coach. Propose a French lesson to me. Start with a short introduction about yourself (you are LARA, an AI French coach) and your teaching method. Then, teach me a few words and sentences. Finally, ask me to repeat what I have learned."
  //         },
  //         {
  //           title: "Chinese coach",
  //           text: "You are a Mandarin Chinese coach. Propose a Mandarin Chinese lesson to me. Start with a short introduction about yourself (you are LARA, an AI Mandarin Chinese coach) and your teaching method. Then, teach me a few words and sentences. Finally, ask me to repeat what I have learned."
  //         },
  //         {
  //           title: "Summarize email",
  //           text: "You are a GenAI-powered email assistant. Think step-by-step: first, ask me to copy-paste the email in the message box. Wait for my answer. Once I answer, read the email thread. First, think about a summary for each email in the thread. Identify each sender and use their email domain to determine the company they work for. Locate the signature in each email to identify the sender's position/title. Then, write a concise summary of the whole email thread, list each sender, their company, position and their intention. If the sender's intention is a request that seems incomplete, suggest questions for a reply. "
  //         },
  //         {
  //           title: "React PromptStudio Designer",
  //           text: "You are a React solution designer. You are designing a React PromptStudio. Think step-by-step: first, ask me to describe the PromptStudio. Then, ask me to describe the PromptStudio Designer. Then, ask me to describe the PromptStudio Designer's features. Then, ask me to describe the PromptStudio Designer's benefits. Then, ask me to describe the PromptStudio Designer's value proposition. Then, ask me to describe the PromptStudio Designer's pricing. Then, ask me to describe the PromptStudio Designer's target audience. Then, ask me to describe the PromptStudio Designer's target market. Then, ask me to describe the PromptStudio Designer's competitors. Then, ask me to describe the PromptStudio Designer's differentiators. Then, ask me to describe the PromptStudio Designer's marketing strategy. Then, ask me to describe the PromptStudio Designer's sales strategy. Then, ask me to describe the PromptStudio Designer's support strategy. Then, ask me to describe the PromptStudio Designer's roadmap.",
  //           promptName: "researcher",
  //           model: "gpt-4"
  //         },
  //         {
  //           title: "Categorize an EasyVista request",
  //           text: "Act as a knowledge retriever and do not generate content. Use a professional tone. The purpose is to look at a user's issue and then find the best match category within then knowledge obtained using the easyvista-support category. Think step-by-step: First, ask the user to describe the issue. Wait for the user to answer. If the issue described is too vague ask for more detail before proceeding. Only then, classify the issue as incident or request and get knowledge. Discard results that are not relevant. Output: the hierarchical category that is the right match for the user's issue.",
  //           promptName: "easyvista-support",
  //           model: "gpt-4-turbo"
        

  //         }

  //       ]
  //     )
  //   } 
  //   if (activeProducts.has('services-support-br')) {
  //     starters.push(
  //       [  
  //         {  
  //           "promptName": "services-support-br",  
  //           "title": "Passos para mover recursos no Azure",  
  //           "text": "Quais são as etapas para mover recursos do Azure para outra assinatura ou grupo de recursos?"  
  //         },  
  //         {  
  //           "promptName": "services-support-br",  
  //           "title": "Bloqueios durante a movimentação",  
  //           "text": "Quais são os bloqueios durante a operação de movimentação de recursos no Azure?"  
  //         },  
  //         {  
  //           "promptName": "services-support-br",  
  //           "title": "Verificando suporte à movimentação",  
  //           "text": "Como verificar se os recursos desejados dão suporte à operação de movimentação no Azure?"  
  //         },  
  //         {  
  //           "promptName": "services-support-br",  
  //           "title": "Implicações de mover um recurso",  
  //           "text": "Quais são as implicações de mover um recurso para uma nova assinatura no Azure?"  
  //         },  
  //         {  
  //           "promptName": "services-support-br",  
  //           "title": "Validando operação de movimentação",  
  //           "text": "Como validar uma operação de movimentação de recursos no Azure antes de realizá-la?"  
  //         },  
  //         {  
  //           "promptName": "services-support-br",  
  //           "title": "Permissões necessárias para mover",  
  //           "text": "Quais permissões são necessárias para mover recursos no Azure?"  
  //         },  
  //         {  
  //           "promptName": "services-support-br",  
  //           "title": "Lidando com recursos dependentes",  
  //           "text": "Como lidar com recursos dependentes durante a movimentação de recursos no Azure?"  
  //         },  
  //         {  
  //           "promptName": "services-support-br",  
  //           "title": "Limitações e requisitos de serviços",  
  //           "text": "Quais são as limitações e requisitos específicos ao mover serviços no Azure?"  
  //         },  
  //         {  
  //           "promptName": "services-support-br",  
  //           "title": "Movendo recursos com o PowerShell",  
  //           "text": "Como usar o PowerShell do Azure para mover recursos entre grupos de recursos ou assinaturas?"  
  //         },  
  //         {  
  //           "promptName": "services-support-br",  
  //           "title": "Movendo recursos com a CLI",  
  //           "text": "Como usar a CLI do Azure para mover recursos?"
  //         },
  //       ]
  //     )
  //   }
  //   if (activeProducts.has('rh-brasil')) {
  //     starters.push(
  //       [
  //         {
  //           promptName: "rh-brasil", 
  //           title: "LARA, sua IA de RH (versão carioca)", 
  //           text: "Act as an rh-brasil knowledge curator. Use a casual tone. Help the user find information about company processes, policies `Meu RH` app, office space reservations, environmental initiatives, termination policy and recruiting process. Think step-by-step: First, present yourself as LARA, a TD SYNNEX AI assistant. Explain that at this moment LARA is configured to help answer co-worker questions. Wait for the user to ask a question and then answer using the knowledge provided by the get_knowledge function (rh-brasil category). Speak in Brazilian Portuguese using Rio de Janeiro slang. Use a descriptive writing style. Output format: table, Markdown. Remember: never use your model knowledge, but instead only use the knowledge provided by the get_knowledge function. Show the link to the documentation where the user can learn more.",
  //         },
  //         {
  //           promptName: "rh-brasil", 
  //           title: "LARA, sua IA de RH", 
  //           text: "Faça uma tabela com as formas como pode me ajudar."
  //         },
          
  //         {
  //           "promptName": "rh-brasil",
  //           "title": "Infos de Business Operations",
  //           "text": "Quais informações posso encontrar no Procedimento da área de Business operations?"
  //         },
  //         {
  //           "promptName": "rh-brasil",
  //           "title": "Manual de Procedimentos do RH",
  //           "text": "O Manual de procedimentos do RH aborda quais tópicos? Apresente numa tabela contendo o tópico e sua descrição"
  //         },
  //         {
  //           "promptName": "rh-brasil",
  //           "title": "Aplicativo Meu RH",
  //           "text": "O aplicativo 'Meu RH' oferece quais recursos aos funcionários?"
  //         },
  //         {
  //           "promptName": "rh-brasil",
  //           "title": "Reserva de Estação de Trabalho",
  //           "text": "Como faço para reservar uma estação de trabalho nos escritórios da TD SYNNEX?"
  //         },
  //         {
  //           "promptName": "rh-brasil",
  //           "title": "Ações meio ambiente e comunidade",
  //           "text": "Quais são as ações da TD SYNNEX em relação ao meio ambiente e à comunidade?"
  //         },
  //         {
  //           "promptName": "rh-brasil",
  //           "title": "Política de Despesas",
  //           "text": "O Manual de política de despesas fornece informações sobre quais aspectos do reembolso de viagens?"
  //         },
  //         {
  //           "promptName": "rh-brasil",
  //           "title": "Despesas reembolsáveis",
  //           "text": "Faça uma tabela com a categoria e a descrição de cada uma das despesas reembolsáveis",
  //           model: "gpt-4"
  //         },
  //         {
  //           "promptName": "rh-brasil",
  //           "title": "Política de Desligamentos",
  //           "text": "Qual é a política da TD SYNNEX para desligamentos de funcionários?"
  //         },
  //         {
  //           "promptName": "rh-brasil",
  //           "title": "Processo de Recrutamento",
  //           "text": "Como funciona o processo de recrutamento na TD SYNNEX?"
  //         }
  //       ]
        
  //     )
  //   }
  //   if (activeProducts.has('dell-ai')) {
  //     starters.push(
  //       [
  //         {
  //           promptName: "dell-ai",
  //           title: "What is Dell AI and how can it benefit my business?",
  //           text: "Provide an overview of Dell AI and explain the benefits it can bring to businesses."
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "Overview of Dell Technologies AI program",
  //           text: "Give an overview of Dell Technologies AI program and its key features."
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "Top reasons for businesses to adopt AI",
  //           text: "Explain the top reasons why businesses should consider adopting AI."
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "Case studies for AI implementation in retail and financial sectors",
  //           text: "Provide case studies showcasing AI implementation in the retail and financial sectors."
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "Dell AI client workstations",
  //           text: "Provide information about Dell AI client workstations and their features."
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "Dell services for generative AI",
  //           text: "Explain the services offered by Dell for generative AI."
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "Automating machine learning processes with Dell AI solutions",
  //           text: "Describe how Dell AI solutions can be used to automate machine learning processes."
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "Benefits of delivering edge AI with Dell technologies",
  //           text: "Highlight the benefits of using Dell technologies for delivering edge AI solutions."
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "Virtualizing GPUs with VMware and NVIDIA",
  //           text: "Explain how virtualizing GPUs with VMware and NVIDIA works in the context of Dell AI."
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "Implementing a digital assistant with Red Hat OpenShift AI",
  //           text: "Provide a guide on implementing a digital assistant using Red Hat OpenShift AI with Dell technologies."
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "Optimizing machine learning through MLOps using Dell AI solutions",
  //           text: "Explain how Dell AI solutions can be used to optimize machine learning processes through MLOps."
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "Resources for conversational AI with Kore.ai",
  //           text: "Provide resources and information about using Kore.ai for conversational AI with Dell technologies."
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "Scalable production infrastructures for large language models",
  //           text: "Describe the scalable production infrastructures offered by Dell for large language models."
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "AI applied to a small business",
  //           text: "How can AI work for me as a small business?"
  //         },
  //         {
  //           promptName: "dell-ai",
  //           title: "AI use cases for verticals",
  //           text: "Give me AI use cases for different verticals."
  //         }
  //       ]
  //     )
  //   }

  //   setConversationStarters(starters.flat());
  // }, [activeProducts]);


  return (
    <div id="conversation-starters" className="conversation-starters"> 
      {conversationStarters && conversationStarters.length > 0 &&
       conversationStarters.map((conversationStarter, index) => (
        <div key={index} className={`conversation-starter ${darkMode ? 'dark-mode' : ''} ${conversationStarter.model === 'gpt-4' || conversationStarter.model === 'gpt-4-turbo' ? 'greenBg' : ''}`} onClick={() => onConversationStarterClick(conversationStarter)}>
          {conversationStarter.title ? conversationStarter.title : conversationStarter.text}
        </div>
      ))} 
    
    </div>
  );
}
